<template>
    <div class=" bg-blue-100 flex flex-col justify-start items-center w-full min-h-screen font-script">
        <template v-if="noGifts">
            <div class=" h-screen flex flex-col justify-between items-center">
                <div class="w-full h-full flex justify-center items-center bg-gray-500">
                    <h1 class="text-white text-2xl p-4">Tu n'as pas encore pris de cadeau !🥺</h1>
                </div>
                <img src="/img/grinchNoPresent.gif" class="mb-10">
            </div>
        </template>
        <template v-if="gifts?.length > 0">
            <div class="w-full last:mb-16">
                <gifts-report />
            </div>
            <div v-for="gift in gifts" :key="gift.id" class="w-full px-4 last:mb-16">
                <gifts-item :gift="gift" />
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import GiftsItem from './GiftsItem.vue';
import GiftsReport from './GiftsReport.vue';
export default {
  components: { GiftsItem, GiftsReport },
    name: 'GiftsAll',
    computed:{
        ...mapState(['gifts']),
        noGifts(){
            if (this.gifts?.length === 0){
                return true;
            }
            return false;
        },
    },
    beforeMount(){
        this.getGifts();
    },
    methods:{
        ...mapActions(['getGifts']),
    },
}
</script>