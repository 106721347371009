<template>
    <div class=" flex flex-col justify-center items-center bg-gray-50 bg-opacity-90 w-full p-2">
        <h3 class="text-black font-script text-xl">Tu as pris <span class="text-blue-500 font-bold">{{gifts?.length}}</span> cadeau{{gifts?.length > 1 ? 'x':''}} 🎁</h3>
        <h3 class="text-black font-script text-xl">Pour un montant total de <span class="text-blue-500 font-bold">{{totalPrice}}</span>€ 🎄</h3>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'GiftsReport',
    computed:{
        ...mapState(['gifts']),
        totalPrice(){
            let total = 0;
            this.gifts?.map((gift) => {
                if (gift.price) {
                    total += parseFloat(gift.price);
                }
                
            });
            return total;
        },
    },
}
</script>