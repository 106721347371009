<template>
    <div class=" flex flex-row items-center bg-gray-50 bg-opacity-90 w-full rounded-xl mt-4">
        <template v-if="loading">
            <div class="flex justify-center items-center w-full h-full bg-white rounded-xl">
                <img src="/img/Wedges-3s-200px.svg" style="height: 100px; width: 100px;">
            </div>
        </template>
        <template v-if="!loading">
            <div loading="lazy" class=" w-1/3  bg-contain bg-no-repeat bg-center rounded-l-xl bg-gift mx-2  h-36"></div>
            <div class="w-2/3 h-full flex flex-col justify-around items-center p-2">
                <h3 class="text-black font-script text-xl">{{gift.title}}</h3>
                <p v-if="username" class="text-lg text-red-500 font-normal leading-relaxed">Pour {{username}}</p>
                <gifts-view :gift="gift" :username="username" />
            </div>
        </template>
    </div>
</template>

<script>
import {supabase} from '../../supabase';
import GiftsView from './GiftsView.vue';
export default {
  components: { GiftsView },
    name: 'GiftsItem',
    props: {
        gift: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            username: null,
            loading: true,
        }
    },
    beforeMount() {
        this.getProfilId();
    },
    methods:{
        getProfilId(){
            this.loading = true;
            supabase.from('lists').select('profile_id').eq('id', this.gift.list_id)
            .then(data => {
                this.getName(data.body[0].profile_id);
            })
        },
        getName(profil_id){
            supabase.from('profiles').select('username').eq('id', profil_id)
            .then(data => {
                this.username = data.body[0].username;
                this.loading = false;
            })
        }
    }

}
</script>

<style>

</style>