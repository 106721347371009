<template>
    <div>
        <gifts-all />
        <navbar/>
    </div>
</template>

<script>
import GiftsAll from '../components/gifts/GiftsAll.vue'
import Navbar from '../components/Navbar.vue'
export default {
    name: 'Gifts',
    components: { Navbar, GiftsAll },
}
</script>