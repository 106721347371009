<template>
        <div>
        <button class="bg-gradient-to-r from-blue-600 to-blue-500  text-white py-2 px-4 rounded hover:from-blue-500 hover:to-blue-400" v-on:click="toggleModal()">
            Voir le cadeau
        </button>
        <div v-if="showEdit" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-full" style="max-width: 600px">
                <div class="relative px-4 py-10 bg-white shadow rounded-3xl sm:p-10">
                    <div v-if="!toTake" class="max-w-md mx-auto text-xl">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 w-full font-semibold self-start text-gray-700">
                                <h2 class="leading-relaxed text-2xl">{{gift.title}}</h2>
                                <div class="flex flex-row justify-between items-center">
                                    <p v-if="gift.price" class="font-normal">Prix : <span class="font-bold text-blue-400">{{gift.price}}€</span></p>
                                    <a v-if="gift.link" :href="gift.link" target="_blank">
                                        <button class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center text-white px-2 py-1 rounded-md focus:outline-none">
                                            Lien du cadeau
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                            </svg>    
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="gift.comment" class="flex flex-col pl-2 mt-4">
                            <blockquote class="leading-loose bg-blue-100 rounded p-2 max-h-64 overflow-scroll">{{gift.comment}}</blockquote>
                            <figcaption>- {{username}}</figcaption>
                        </div>
                        <div class="pt-4 flex items-center justify-end space-x-4">
                            <button v-if="!gift.done" v-on:click="takegift" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-3 py-2 rounded-md focus:outline-none">Je prends ! </button>
                            <button class="flex justify-end items-center bg-gray-200 text-gray-900 px-3 py-2 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showEdit" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
export default {
    name: 'GiftsView',
    props: {
        gift: {
            type: Object,
            required: true
        },
        username: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            showEdit: false,
        }
    },
    methods: {
        toggleModal() {
            this.showEdit = !this.showEdit
        },
    },
}
</script>